import React from 'react'
import Image from 'gatsby-plugin-sanity-image'
import SanityBlockContent from '@sanity/block-content-to-react'
import { sanityConfig, serializers } from '../utils/helpers'
import * as blogStyles from '../styles/modules/blog.module.scss'

const BlogPost = ({ node, articleID }) => {
  const blog = node.node
  const image = blog?.blogImage?.image
  const alt = image && blog.blogImage.alt ? blog.blogImage.alt : ''
  return (
    <div className={blogStyles.posts}>
      {image ? (
        <div className={blogStyles.image}>
          <Image {...image} alt={alt} />
        </div>
      ) : (
        ''
      )}
      <article id={articleID}>
        <h2>{blog.title}</h2>
        <div>
          <em>{blog.publishedAt.split('T')[0]}</em>
        </div>
        {blog?._rawBody ? (
          <SanityBlockContent
            blocks={blog._rawBody}
            serializers={serializers}
            {...sanityConfig}
          />
        ) : (
          ''
        )}
      </article>
    </div>
  )
}

export default BlogPost
