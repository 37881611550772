import { graphql } from 'gatsby'
import React from 'react'
import Layout from '../components/layout'
import Seo from '../components/seo'
import BlogPost from '../components/blog-post'
import * as blogStyles from '../styles/modules/blog.module.scss'

const Blog = ({ data }) => {
  const blogNav = data.allSanityBlog.edges.map((edge, index) => (
    <li key={index}>
      <a href={`#post-${edge.node.slug.current}`}>{edge.node.title}</a>
    </li>
  ))
  const blogPosts = data.allSanityBlog.edges.map((edge, index) => (
    <BlogPost node={edge} key={index} articleID={`post-${edge.node.slug.current}`} />
  ))
  return (
    <Layout inactive>
      <Seo title="Blog" />
      <div className={blogStyles.top}>
        <h1>Blog</h1>
      </div>
      <div className={blogStyles.body}>
        <nav className={blogStyles.navBar}>
          <h3>Posts</h3>
          <ul>{blogNav}</ul>
        </nav>
        <div>{blogPosts}</div>
      </div>
    </Layout>
  )
}

export default Blog

export const query = graphql`
  query BlogQuery {
    allSanityBlog(sort: { fields: publishedAt, order: DESC }) {
      edges {
        node {
          id
          title
          slug {
            current
          }
          _rawBody
          publishedAt
          blogImage {
            alt
            image {
              ...ImageWithPreview
            }
          }
        }
      }
    }
    sanityBasicPage(_id: { eq: "blog" }) {
      title
      _rawBody
    }
  }
`
